import React from "react";
import { styled } from "styled-components";
import theme from "../../../theme";
import { CRMS } from "../../../utils/constants";
import { isSpreadsheetType } from "../../../utils/utils";

function CandidateTable({
  columns,
  candidateList,
  setSort,
  sort,
  handleCheckContact,
  checkedContacts,
  crm,
  isCandidates,
  contactsLabel,
}) {
  const isSpreadsheet = isSpreadsheetType(crm);
  const isClientContacts = !isCandidates;

  const handleClickSort = (newSort) => {
    setSort({
      newSort,
      newOrder: sort.sort !== newSort || sort.order === "desc" ? "asc" : "desc",
    });
  };

  const getSortIcon = (sortField) => {
    return (
      <SortIcon
        src={`/images/sort-${
          sort.sort === sortField ? sort.order + "-icon" : "icon"
        }.png`}
        alt="sort"
      />
    );
  };

  const columnsToDisplay = columns?.filter(
    (col) => col !== "Email" && col !== "Email Address" && col !== "Name"
  );

  return (
    <>
      <Table>
        <TableHead>
          <TableRow
            $backgroundColor={theme.colors.candidate_table_head_color2}
            $height="30px"
            $borderTop="1px solid #D6D6D6"
          >
            <TableHeadData>
              {candidateList.length > 0 ? (
                <Checkbox
                  type="checkbox"
                  name="selectAll"
                  id="select-all-contacts-checkbox"
                  checked={checkedContacts.length === candidateList.length}
                  value={checkedContacts.length === candidateList.length}
                  onChange={() =>
                    checkedContacts.length !== candidateList.length
                      ? handleCheckContact("all")
                      : handleCheckContact("none")
                  }
                />
              ) : (
                <Checkbox type="checkbox" name="selectAll" disabled />
              )}
            </TableHeadData>
            {!isSpreadsheet && (
              <TableHeadData
                $cursorPointer={true}
                onClick={() => handleClickSort("id")}
              >
                <div>
                  ID
                  {getSortIcon("id")}
                </div>
              </TableHeadData>
            )}
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("full_name")}
            >
              <div>
                {`${contactsLabel.substring(0, contactsLabel.length - 1)} Name`}
                {getSortIcon("full_name")}
              </div>
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("email")}
            >
              <div>
                Email
                {getSortIcon("email")}
              </div>
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("emailStatus")}
            >
              <div>
                Status
                {getSortIcon("emailStatus")}
              </div>
            </TableHeadData>
            {!isSpreadsheet ? (
              <>
                <TableHeadData
                  $cursorPointer={true}
                  onClick={() => handleClickSort("phoneNumber")}
                >
                  <div>
                    Phone
                    {getSortIcon("phoneNumber")}
                  </div>
                </TableHeadData>
                {isClientContacts ? (
                  <>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("jobTitle")}
                    >
                      <div>
                        {crm !== CRMS.BULLHORN ? "Job" : ""} Title
                        {getSortIcon("jobTitle")}
                      </div>
                    </TableHeadData>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("company")}
                    >
                      <div>
                        Company
                        {getSortIcon("company")}
                      </div>
                    </TableHeadData>
                  </>
                ) : (
                  <>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("category")}
                    >
                      <div>
                        Category
                        {getSortIcon("category")}
                      </div>
                    </TableHeadData>
                    <TableHeadData
                      $cursorPointer={true}
                      onClick={() => handleClickSort("specialty")}
                    >
                      <div>
                        Specialty
                        {getSortIcon("specialty")}
                      </div>
                    </TableHeadData>
                  </>
                )}
              </>
            ) : (
              columnsToDisplay?.map((col) => (
                <TableHeadData key={col} className="custom-col">
                  {col}
                </TableHeadData>
              ))
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {candidateList.length !== 0 ? (
            candidateList.map((recipient, index) => {
              const checked = checkedContacts.includes(recipient.id);
              return (
                <TableRow
                  $isChecked={checked}
                  $isInvalid={recipient.isInvalid}
                  $backgroundColor={recipient.isInvalid ? "#ffe4e480" : ""}
                  $borderBottom={`1px solid #E8E8E8`}
                  key={index}
                >
                  <TableData color={theme.colors.purple}>
                    <CheckboxBlock>
                      <Checkbox
                        id={`select-contact-${recipient.id}`}
                        type="checkbox"
                        checked={checked}
                        value={checked}
                        onChange={(event) =>
                          handleCheckContact(event.target.checked, recipient.id)
                        }
                      />
                    </CheckboxBlock>
                  </TableData>
                  {!isSpreadsheet && <TableData>{recipient.id}</TableData>}
                  <TableData color={theme.colors.purple}>
                    {recipient.full_name}
                  </TableData>
                  <TableData>{recipient.email}</TableData>
                  <TableData>{recipient.emailStatus}</TableData>
                  {!isSpreadsheet ? (
                    <>
                      <TableData>{recipient.phoneNumber}</TableData>
                      {isClientContacts ? (
                        <>
                          <TableData>{recipient.jobTitle}</TableData>
                          <TableData>{recipient.company}</TableData>
                        </>
                      ) : (
                        <>
                          <TableData>{recipient.category}</TableData>
                          <TableData>{recipient.specialty}</TableData>
                        </>
                      )}
                    </>
                  ) : (
                    columnsToDisplay?.map((col) => (
                      <TableData key={col}>{recipient.row?.[col]}</TableData>
                    ))
                  )}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableData colSpan="100">No recipients to show</TableData>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default CandidateTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  position: sticky;
  top: -1px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.candidate_table_head_color2};
`;

const TableHeadData = styled.th`
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? "pointer" : "")};
  font-size: 11px;
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding};`
      : `
          padding: 0 8px 0 0;
          &:first-of-type {
            padding: 0 8px 0 8px;
          }
    `};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  background-color: ${({ theme }) => theme.colors.candidate_table_head_color2};

  &,
  * {
    font-weight: 700;
    font-family: "AlbertSansExtraBold", sans-serif;
    color: ${({ theme }) => theme.colors.black_o70};
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
  }

  &.custom-col {
    min-width: 150px;
  }
`;

const TableRow = styled.tr`
  height: ${({ $height }) => ($height ? $height : "46px")};
  ${({ $backgroundColor, theme }) =>
    $backgroundColor ? `background-color:${$backgroundColor}` : ""};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
  ${({ $borderTop }) => ($borderTop ? `border-top: ${$borderTop}` : "")};
  ${({ $isInvalid }) =>
    $isInvalid
      ? `
          td:not(:first-of-type)  {
            opacity:0.5;
          }
    `
      : ""};
  ${({ $isChecked }) =>
    $isChecked
      ? ""
      : `
          &:not(:hover) {
            >td>div>input[type="checkbox"] {
              visibility: hidden;
            }
          }
    `};
`;

const TableData = styled.td`
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding};`
      : `
          padding: 0 8px 0 0;
          &:first-of-type {
            padding: 0 8px 0 8px;
          }
    `};
  color: ${({ color, theme }) => color || theme.colors.black_o70};
  font-size: ${({ color }) => (color ? "12px" : "11px")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
`;

const SortIcon = styled.img`
  width: 11px;
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  display: block !important;
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin-bottom: 0;
  &:not(:checked) {
    opacity: 0.4;
    &:hover {
      opacity: 0.8;
    }
    &[name="selectAll"] {
      box-shadow: inset 0 0 3px #fff;
      opacity: 0.6;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &:disabled {
    pointer-events: none;
    &[name="selectAll"] {
      box-shadow: inset 0 0 3px #fff;
      opacity: 1;
    }
  }
`;
