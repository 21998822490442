import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import SelectedContactsTable from "./SelectedContactsTable";
import { FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS } from "../../../utils/constants";
import CandidateTableFilters from "./CandidateTableFilters";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import { OFFSET_LEFT } from "../styles";
import Search from "./Search";

function SelectedContacts({
  isCandidates,
  contactsLabel,
  contactsData,
  campaignType,
  crm,
  onClickEdit,
  checkedContacts,
  setCheckedContacts,
  onRemoveSelected,
  relevantStatuses,
}) {
  const [contactsList, setContactsList] = useState(contactsData);
  const parentRef = useRef(null);
  const [sort, setSort] = useState({ sort: null, order: null });
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState("");

  const filterCandidatesBySort = async ({ newSort, newOrder }) => {
    setSort({ sort: newSort, order: newOrder });

    const sortMultiplier = newOrder === "desc" ? 1 : -1;
    const sortedContactsList = contactsList.sort(
      (a, b) => sortMultiplier * (a[newSort] < b[newSort] ? 1 : -1)
    );

    setContactsList(sortedContactsList);

    return sortedContactsList;
  };

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.selectRecipients,
    parentRef,
  };

  const handleCheckContact = (isChecked, contactId) => {
    if (isChecked === "all") {
      setCheckedContacts(contactsList.map((c) => c.id));
      return;
    }
    if (isChecked === "none") {
      setCheckedContacts([]);
      return;
    }

    setCheckedContacts(
      isChecked
        ? [...checkedContacts, contactId]
        : checkedContacts.filter((c) => c !== contactId)
    );
  };

  const filterCandidatesByFilters = (newFilters) => {
    const filteredContacts = contactsData.filter((c) => {
      return Object.keys(newFilters).every(
        (filter) => c[filter] === newFilters[filter]
      );
    });

    setContactsList(filteredContacts);
    return filteredContacts;
  };

  const filterCandidatesByQuery = (currentQuery, contacts) => {
    if (currentQuery === "") {
      setContactsList(contacts);
      return;
    }
    const filteredContacts = contacts.filter((c) => {
      return (
        c.full_name?.toLowerCase().includes(currentQuery.toLowerCase()) ||
        c.email?.toLowerCase().includes(currentQuery.toLowerCase()) ||
        c.company?.toLowerCase().includes(currentQuery.toLowerCase()) ||
        c.jobTitle?.toLowerCase().includes(currentQuery.toLowerCase())
      );
    });

    setContactsList(filteredContacts);
  };

  useEffect(() => {
    setContactsList(contactsData);
    const filteredContacts = filterCandidatesByFilters(filters);
    filterCandidatesByQuery(query, filteredContacts);
  }, [contactsData]);

  useEffect(() => {
    const filteredContacts = filterCandidatesByFilters(filters);
    filterCandidatesByQuery(query, filteredContacts);
  }, [query, filters]);

  return (
    <SelectedContactsContainer>
      <Header>
        <TitleBlock>
          <Title>
            {contactsData.length}{" "}
            {contactsLabel.substring(0, contactsLabel.length - 1).toLowerCase()}
            {contactsData.length > 1 && "s"} selected
          </Title>
          <FiltersBlock>
            <CandidateTableFilters
              load={setFilters}
              relevantStatuses={relevantStatuses}
            />
            <Search value={query} onChange={setQuery} />
          </FiltersBlock>
        </TitleBlock>
        <CtrlBlock>
          {checkedContacts.length > 0 && (
            <div onClick={onRemoveSelected}>Remove Selected</div>
          )}
          <div onClick={onClickEdit}>Select Different Contacts</div>
        </CtrlBlock>
      </Header>
      <TableContainer ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <SelectedContactsTable
          columns={contactsData?.[0]?.columns}
          candidateList={contactsList}
          setSort={(sort) => filterCandidatesBySort(sort)}
          sort={sort}
          handleCheckContact={handleCheckContact}
          checkedContacts={checkedContacts}
          crm={crm}
          campaignType={campaignType}
          contactsLabel={contactsLabel}
          isCandidates={isCandidates}
        ></SelectedContactsTable>
      </TableContainer>
    </SelectedContactsContainer>
  );
}

export default SelectedContacts;

const SelectedContactsContainer = styled.div`
  height: 100%;
  border-radius: 10px 10px 0 0;
`;

const Header = styled.div`
  padding: 15px 60px 15px calc(${OFFSET_LEFT} - 21px);
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 60px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
`;

const CtrlBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;

  > div {
    color: ${({ theme }) => theme.colors.blueLight};
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.025em;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }
  }
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 19px;
`;

const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
  text-align: left;
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 23px;
  position: relative;
`;

const TableContainer = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
  position: relative;
`;
